
import { defineComponent } from "vue";
import Lock from "@/assets/svg/Lock.svg?inline";
import MainButton from "@/components/atoms/buttons/MainButton.vue";
import Discord from "@/assets/svg/discord-blue.svg?inline";
import Twitter from "@/assets/svg/twitter-blue.svg?inline";
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "CollectionDetailsSection",
  data() {
    return {
      myNFT: {},
      settings: {
        itemsToShow: 4,
        snapAlign: "start",
      },
      slidesCollect: [
        require("../../../assets/image/test4.png"),
        require("../../../assets/image/test5.png"),
        require("../../../assets/image/test6.png"),
        require("../../../assets/image/test4.png"),
        require("../../../assets/image/test5.png"),
        require("../../../assets/image/test6.png"),
      ],
    };
  },
  components: {
    Lock,
    MainButton,
    Discord,
    Twitter,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  mounted() {
    this.getNft();
  },
  methods: {
    async getNft() {
      const NFTs = require("@/plugins/solana-nft-tools");
      const web3 = require("@solana/web3.js");
      const conn = new web3.Connection(
        web3.clusterApiUrl("devnet"),
        "confirmed"
      );
      this.myNFT = await NFTs.getNFTByMintAddress(conn, this.$route.params.id);
    },
  },
});
